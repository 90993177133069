import {getTimeFromSeconds, isBooleanAttributeTrue} from '../utils/index.js';

import styles from './input.style.css';

export class SketchTime extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    static $select;

    $internals;

    $fallbackmode = false;

    $shadowRoot;

    static get observedAttributes() {
        return [
            'disabled',
            'fieldId',
            'label',
            'name',
            'required',
            'step',
            'timeFormat',
            'value',
            'subgrid',
            'minTime',
            'maxTime',
        ];
    }

    constructor() {
        super();
        /* for form elements */
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get value() {
        return this.getAttribute('value');
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    get timeFormat() {
        return this.getAttribute('timeFormat') || 'HH:mm';
    }

    set timeFormat(value) {
        this.setAttribute('timeFormat', value);
    }

    get step() {
        return this.getAttribute('step') || '900';
    }

    set step(value) {
        this.setAttribute('step', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get disabled() {
        return this.getAttribute('disabled');
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get fieldId() {
        return this.getAttribute('fieldId');
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get required() {
        return this.getAttribute('required');
    }

    set required(value) {
        this.setAttribute('required', value);
    }

    get subgrid() {
        return isBooleanAttributeTrue(this.getAttribute('subgrid'));
    }

    set subgrid(value) {
        this.setAttribute('subgrid', value);
    }

    get minTime() {
        return this.getAttribute('minTime') || 0;
    }

    set minTime(value) {
        this.setAttribute('minTime', value);
    }

    get maxTime() {
        return this.getAttribute('maxTime') || 86400;
    }

    set maxTime(value) {
        this.setAttribute('maxTime', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
        }
        this._bindEvents();
    }

    disconnectedCallback() {
        this._unbindEvents();
    }

    _bindEvents() {
        this.$select = this.$shadowRoot.querySelector('sketch-select');
        this.$select.addEventListener('change', this._handleTimeSelection);
    }

    _unbindEvents() {
        this.$select?.removeEventListener('change', this._handleTimeSelection);
    }

    attributeChangedCallback(property, oldValue, newValue) {
        if (property === 'value' && oldValue !== newValue) {
            this.render();
            this._bindEvents();
        }
    }

    _buildIntervals(seconds) {
        // calculate time intervals from 0 hours until 24 hours in seconds
        const keyValues = {};
        for (let i = 0; i <= 24 * 3600; i += seconds) {
            if (i >= this.minTime && i <= this.maxTime) {
                keyValues[i] = this._formatTimeFromSeconds(i);
            }
        }
        return keyValues;
    }

    _formatTimeFromSeconds(seconds) {
        const extractedTime = getTimeFromSeconds(seconds);

        const formattedHours = String(extractedTime.hours).padStart(2, '0');
        const formattedMinutes = String(extractedTime.minutes).padStart(2, '0');

        let formatted = this.timeFormat.replace('HH', formattedHours);
        formatted = formatted.replace('mm', formattedMinutes);
        return formatted;
    }

    _renderTimeSlots = (minutes) =>
        Object.keys(minutes).reduce(
            (accumulator, currentkey) =>
                `${accumulator}<option ${currentkey === this.value ? 'selected' : ''} value="${currentkey}">${minutes[currentkey]}</option>`,

            ''
        );

    /**
     * Handle time selection
     * @param {*} event
     */
    _handleTimeSelection = (event) => {
        this.value = event.detail.value;
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
        }
        const changeEvent = new CustomEvent('change', {
            bubbles: true,
            detail: {
                value: this.value,
            },
        });
        this.dispatchEvent(changeEvent);
    };

    render() {
        const minutes = this._buildIntervals(Number.parseInt(this.step));
        this.$shadowRoot.innerHTML = `
        <style>
        :host {
            ${this.subgrid ? 'grid-column: span 2;' : ''}
        }
        @media only screen and (min-width: 576px) {
            :host {
                ${
                    this.subgrid
                        ? `display: grid;
                grid-template-columns: subgrid;`
                        : ''
                }
                
            }
        }
        </style>

        <sketch-select 
            label="${this.label}" 
            ${this.subgrid ? 'subgrid' : ''} 
            value="${this.value}" 
            fieldId="${this.fieldId}">${this._renderTimeSlots(minutes)}</sketch-select>
`;
    }
}
