export class SketchCollapseSection extends HTMLElement {
    _maincontent;

    _collapsibleContent;

    _isTransitioning = false;

    _collapseicon;

    static get observedAttributes() {
        return ['iconClosedState', 'iconOpenState', 'title', 'state'];
    }

    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    get title() {
        return this.getAttribute('title');
    }

    set title(value) {
        this.setAttribute('title', value);
    }

    get state() {
        return this.getAttribute('state') || 'closed';
    }

    set state(value) {
        this.setAttribute('state', value);
    }

    get iconClosedState() {
        return this.getAttribute('iconClosedState') || 'caret-left';
    }

    set iconClosedState(value) {
        this.setAttribute('iconClosedState', value);
    }

    get iconOpenState() {
        return this.getAttribute('iconOpenState') || 'caret-down';
    }

    set iconOpenState(value) {
        this.setAttribute('iconOpenState', value);
    }

    connectedCallback() {
        this.render();
        this._bind();
    }

    _bind = () => {
        this._maincontent = this.shadow.querySelector('.maincontent');
        this._collapsibleContent = this.shadow.querySelector('.collapse');
        this._collapseicon = this.shadow.querySelector('sketch-icon');
        this._maincontent.addEventListener('click', this._handleWrapperClick);
    };

    _unBind = () => {
        this._maincontent?.removeEventListener(
            'click',
            this._handleWrapperClick
        );
    };

    _reflow = (element) => element.offsetHeight;

    _handleWrapperClick = () => {
        if (this.state === 'open') {
            this._collapsibleContent.style.height = `${this._collapsibleContent.getBoundingClientRect().height}px`;
            this._reflow(this._collapsibleContent);
            this._collapsibleContent.classList.add('collapsing');
            this._collapsibleContent.style.height = '0px';
            this._collapsibleContent.classList.remove('collapse', 'show');
            this._isTransitioning = true;
            this._collapseicon.setAttribute('icon', this.iconClosedState);
            setTimeout(() => {
                this._collapsibleContent.classList.remove('collapsing');
                this._collapsibleContent.classList.add('collapse');
                this._isTransitioning = false;
                this.state = 'closed';
            }, 350);
        } else {
            this._collapsibleContent.classList.remove('collapse');
            this._collapsibleContent.classList.add('collapsing');
            this._collapsibleContent.style.height = '0px';
            this._isTransitioning = true;
            this._collapsibleContent.style.height = `${this._collapsibleContent.scrollHeight}px`;
            this._collapseicon.setAttribute('icon', this.iconOpenState);
            setTimeout(() => {
                this._collapsibleContent.classList.remove('collapsing');
                this._collapsibleContent.classList.add('collapse');
                this._collapsibleContent.classList.add('show');
                this._isTransitioning = false;
                this.state = 'open';
            }, 350);
        }
    };

    attributeChangedCallback(property, oldValue, newValue) {
        if (property === 'state') {
            this._unBind();
            this.render();
            this._bind();
        }
    }

    render() {
        this.shadow.innerHTML = `
        <style>
            .wrapper {
                border: var(--sketchBorderSection);
                border-radius: var(--sketchBorderRadiusSection);
                box-shadow: var(--sketchBoxShadowSection);
                padding: var(--sketchSpacing6);

                .maincontent {
                    display: flex;

                    &:hover {
                        cursor: pointer;
                        background-color: var(--sketchColorNeutralExtraLight);
                    }
                }

                .collapsing {
                    height: 0;
                    overflow: hidden;
                    transition: height .35s ease;
                }
                .collapse:not(.show) {
                    display: none;
                }
                .spacing{
                    margin-top: var(--sketchSpacing6);;
                }
            }
        </style>
        <div class="wrapper">
            <div class="maincontent">
                <sketch-text>${this.title}</sketch-text>
                <sketch-icon icon="${this.state === 'open' ? this.iconOpenState : this.iconClosedState}"></sketch-icon>
            </div>
            <div class="collapse ${this.state === 'open' ? 'show' : ''}">
                <div class="spacing"></div>
                <slot></slot>
            </div>
        </div>`;
    }
}
