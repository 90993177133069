/**
 * Converts a javascript date to a MUMPS ISO standard date
 *
 * @param {Date} date - The date to be converted.
 * @return {string} The $h format
 */
export const dateToDollarH = (date, currentMdate = '') => {
    let mDate = '';
    const hstartDate = new Date(1840, 11, 31);
    const difftime = date.getTime() - hstartDate.getTime();
    const hdate = Math.round(difftime / (1000 * 3600 * 24));
    mDate = `${hdate},${
        currentMdate.split(',')[1] ? currentMdate.split(',')[1] : ''
    }`;
    return mDate;
};

/**
 * Converts a MUMPS date ($h format) to a javascript date
 * @param {string} dollarH
 * @returns {Date}
 */
export const dollarHToJSDate = (dollarH) => {
    const newdate = new Date(1840, 11, 31);
    const numberOfDays = parseInt(parseInt(dollarH.split(',')[0]));
    newdate.setDate(newdate.getDate() + numberOfDays);
    newdate.setHours(12);
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    const jsDate = new Date(newdate - timezoneOffset);
    return jsDate;
};

export const generateUniqueId = () =>
    Date.now().toString(36) + Math.random().toString(36).substr(2);

/**
 * Extract hours minutes and seconds from a number of seconds
 * @param {number} seconds
 * @returns {object} {hours, minutes, seconds}
 */
export const getTimeFromSeconds = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor((seconds % 3600) % 60);

    return {hours, minutes, seconds: sec};
};

/**
 * Evaluate if a component attribute should be evaluated as true or false
 * A boolean attribute should evaluate as true when it is passed to the component with value
 * "true" or without value
 * @param {string} value - The received value of the attribute
 * @returns {boolean}
 */
export const isBooleanAttributeTrue = (value) => {
    if (value === 'false' || value === 'true') {
        // attribute value is explicitly specified. Evaluate as boolean
        return value === 'true';
    }
    if (value !== null) {
        // attribute is specified without value
        return true;
    }
    return false;
};
