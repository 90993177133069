export class SketchModal extends HTMLElement {
    /* for form elements */ static formAssociated = true;

    $shadowRoot;

    static get observedAttributes() {
        return ['variant', 'state', 'width', 'position', 'nobackdrop', 'title'];
    }

    constructor() {
        super();
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get state() {
        return this.getAttribute('state') || 'closed';
    }

    set state(value) {
        this.setAttribute('state', value);
    }

    get width() {
        return this.getAttribute('width');
    }

    set width(value) {
        this.setAttribute('width', value);
    }

    get position() {
        return this.getAttribute('position');
    }

    set position(value) {
        this.setAttribute('position', value);
    }

    get title() {
        return this.getAttribute('title');
    }

    set title(value) {
        this.setAttribute('title', value);
    }

    get nobackdrop() {
        const noBackDrop = this.getAttribute('nobackdrop');
        return (
            (noBackDrop !== undefined && noBackDrop !== null) ||
            noBackDrop === 'true'
        );
    }

    set nobackdrop(value) {
        this.setAttribute('nobackdrop', value);
    }

    _handleKeydown = (event) => {
        if (event.key === 'Escape' && this.state === 'open') {
            this._handleCloseClicked();
        }
    };

    connectedCallback() {
        this.render();
        document.addEventListener('keydown', this._handleKeydown);
        const closeButton = this.$shadowRoot.querySelector('.close-btn');
        if (closeButton) {
            closeButton.addEventListener('click', this._handleCloseClicked);
        }
    }

    disconnectedCallback() {
        document.removeEventListener('keydown', this._handleKeydown);
    }

    _handleCloseClicked = () => {
        const event = new MouseEvent('sk-userclose', {
            bubbles: true,
            composed: true,
        });
        this.dispatchEvent(event);
        this._dispatchClose();
    };

    _dispatchClose = () => {
        if (this.position !== 'center') {
            this.$shadowRoot.querySelector('.modal').style.animation =
                `slide-out-${this.slidedirection || 'RTL'} .5s ease`;
        }
        const event = new MouseEvent('sk-close', {
            bubbles: true,
            composed: true,
        });
        setTimeout(
            () => {
                this.$shadowRoot
                    .querySelector('.modal')
                    .classList.add('closed');
                this.style.animation = '';
                this.state = 'closed';
            },
            this.position === 'center' ? 0 : 500
        );
        this.dispatchEvent(event);
    };

    attributeChangedCallback(property, oldValue, newValue) {
        this.render();
        const closeButton = this.$shadowRoot.querySelector('.close-btn');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                this._dispatchClose();
            });
        }
        if (
            property === 'state' &&
            newValue === 'close' &&
            oldValue !== 'closed'
        ) {
            this._dispatchClose();
        }
        if (
            property === 'state' &&
            newValue === 'open' &&
            oldValue !== 'open'
        ) {
            this._setChildrenVisible();
        }
    }

    _setChildrenVisible = () => {
        this.$shadowRoot
            .querySelectorAll('div.content > slot')
            .forEach((slot) => {
                slot.assignedElements().forEach((element) => {
                    if (element.nodeName === 'SKETCH-MAP') {
                        element.removeAttribute('hidden');
                    }
                });
            });
    };

    _setSlideDirection = (position) => {
        this.slidedirection = 'left';
        if (position === 'left') {
            this.slidedirection = 'LTR';
        }
        if (position === 'right') {
            this.slidedirection = 'RTL';
        }
        if (position === 'top') {
            this.slidedirection = 'TDO';
        }
        if (position === 'center') {
            this.slidedirection = 'TDO';
        }
        if (position === 'bottom') {
            this.slidedirection = 'BUP';
        }
    };

    render() {
        const verticalScroll = window.scrollY;
        this._setSlideDirection(this.position);
        let positionOffset = `left: 0;top: ${
            verticalScroll !== 0 ? `${verticalScroll}px` : '0'
        };`;
        if (this.position === 'right') {
            positionOffset = `right:0;top: ${
                verticalScroll !== 0 ? `${verticalScroll}px` : '0'
            };`;
        }
        if (this.position === 'top') {
            positionOffset = `top: ${
                verticalScroll !== 0 ? `${verticalScroll}px` : '0'
            };`;
        }
        if (this.position === 'bottom') {
            positionOffset = `bottom: ${
                verticalScroll !== 0 ? `-${verticalScroll}px` : '0'
            };`;
        }
        if (this.position === 'center') {
            positionOffset = `top: ${
                verticalScroll !== 0 ? `calc(${verticalScroll}px + 50%)` : '50%'
            }; left: 50%;`;
        }

        let backgroundColor = 'var(--sketchColorNeutralExtraLight)';
        let textColor = 'var(--sketchColorText)';
        let hoverColor = 'var(--sketchBackgroundColorSecondaryHover)';
        if (this.variant === 'primary') {
            backgroundColor = 'var(--sketchBackgroundColorPrimary)';
            textColor = 'var(--sketchColorTextOnPrimary)';
            hoverColor = 'var(--sketchBackgroundColorPrimaryHover)';
        }
        if (this.variant === 'tertiary') {
            backgroundColor = 'var(--sketchBackgroundColorTertiary)';
            textColor = 'var(--buttontertiarytextcolor)';
            hoverColor = 'var(--sketchBackgroundColorTertiaryHover)';
        }

        let startPosition = 'translateX(100%)';
        let slideInAnimation = 'slide-in-RTL';
        let slideOutAnimation = 'slide-out-RTL';

        if (this.slidedirection === 'LTR') {
            startPosition = `translateX(calc(-100% - ${this.width || '0px'}))`;
            slideInAnimation = 'slide-in-LTR';
            slideOutAnimation = 'slide-out-LTR';
        }
        if (this.slidedirection === 'BUP') {
            startPosition = 'translateY(100vh)';
            slideInAnimation = 'slide-in-BUP';
            slideOutAnimation = 'slide-out-BUP';
        }
        if (this.slidedirection === 'TDO') {
            if (this.position === 'center') {
                // startPosition = 'translate(-50%, -100%)';
                startPosition = 'translate(-50%, -50%)';
                slideInAnimation = 'slide-in-TDO-center';
                slideOutAnimation = 'slide-out-TDO-center';
            } else {
                startPosition = 'translateY(-100%)';
                slideInAnimation = 'slide-in-TDO';
                slideOutAnimation = 'slide-out-TDO';
            }
        }

        this.$shadowRoot.innerHTML = `
        <style>
        :host {
        }
        .modal {
            box-sizing: border-box;
            display: ${this.state !== 'closed' ? 'block' : 'none'};
            transform:  ${startPosition};
            ${
                this.position !== 'center'
                    ? `animation: ${
                          this.state !== 'closed'
                              ? slideInAnimation
                              : slideOutAnimation
                      } .5s ease;
            animation-fill-mode: ${
                this.state !== 'closed' ? 'forwards' : 'backwards'
            };`
                    : ''
            }
            box-shadow: var(--sketchBoxShadowMedium);
            width: ${this.width || 'fit-content'};
            padding: 2rem;
            background-color: ${backgroundColor};
            color: ${textColor};
            flex-direction: column;
            z-index: 200;
            position: absolute;
            ${positionOffset}
            max-height: 90vh;
        }
        .open {
            display: flex;
        }
        .closed {
            display: none;
            ${this.position !== 'center' ? `transform: translateX(100%);` : 'transform: translate(-50%, -100%);'}
        }
        .close-btn {
            color: ${textColor};
            border: 1px solid var(--sketchColorNeutralDark);
            padding: .5rem .5rem;
            cursor: pointer;
            line-height: 10px;
        }
        .close-btn:hover {
            background-color: ${hoverColor};
        }
        .backdrop {
            background-color: rgba(0,0,0,0.575);
            height:100vh;
            width:100vw;
            position:fixed;
            z-index:100;
            display:${this.state !== 'closed' ? 'block' : 'none'};
            left:0;
            top:0;
        }
        .headwrapper {
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;
        }
        .content {
            overflow: auto;
            max-height: 75vh;
        }
        .actions {
            margin-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            gap: .5rem;
        }
        @keyframes slide-in-RTL {
            0% {
                transform: translateX(100%);
            }
            100% {
                transform: translateX(0);
            }
        }
        @keyframes slide-out-RTL {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(100%);
                display: none;
            }
        }
        @keyframes slide-in-LTR {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0);
            }
        }
        @keyframes slide-out-LTR {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-100%);
                display: none;
            }
        }
        @keyframes slide-in-TDO {
            0% {
                transform: translateY(-100%);
            }
            100% {
                transform: translateY(0);
            }
        }
        @keyframes slide-out-TDO {
            0% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(-100%);
                display: none;
            }
        }
        @keyframes slide-in-TDO-center {
            0% {
                transform: translate(-50%, -100%);
            }
            100% {
                transform: translate(-50%, 150%);
            }
        }
        @keyframes slide-out-TDO-center {
            0% {
                transform: translate(-50%, 150%);
            }
            100% {
                transform: translate(-50%, -100%);
                display: none;
            }
        }
        @keyframes slide-in-BUP {
            0% {
                transform: translateY(100%);
            }
            100% {
                transform: translateY(0);
            }
        }
        @keyframes slide-out-BUP {
            0% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(100%);
                display: none;
            }
        }
        </style>
        
        <div class="modal ${this.state}">
            <div class="headwrapper">
                <sketch-heading variant="heading-2" nomargin>${
                    this.title
                }</sketch-heading>
                <div class="close-btn">&times;</div>
            </div>
            <div class="content">
                <slot><slot>
            </div>
            <div class="actions">
                <slot name="actions"></slot>
            </div>
        </div>
        ${this.nobackdrop ? '' : '<div class="backdrop"></div>'}
        `;
    }
}
