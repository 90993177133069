export class SketchText extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    static get observedAttributes() {
        return ['variant', 'color', 'spacing', 'spacingType'];
    }

    set spacingType(value) {
        this.setAttribute('spacingType', value);
    }

    get spacingType() {
        return this.getAttribute('spacingType');
    }

    set spacing(value) {
        this.setAttribute('spacing', value);
    }

    get spacing() {
        return this.getAttribute('spacing');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set color(value) {
        this.setAttribute('color', value);
    }

    get color() {
        return this.getAttribute('color');
    }

    connectedCallback() {
        this.render();
    }

    get margin() {
        let styling = '';
        if (this.spacing) {
            styling = `margin-bottom: var(--sketchSpacing${this.spacing});`;
            if (this.spacingType === 'top') {
                styling = `margin-top: var(--sketchSpacing${this.spacing});`;
            }
            if (this.spacingType === 'vertical') {
                styling = `margin-top: var(--sketchSpacing${this.spacing});margin-bottom: var(--sketchSpacing${this.spacing});`;
            }
        }
        return styling;
    }

    render() {
        const spacingvar = this.margin;
        this.shadow.innerHTML = `
        <style>
        @media print {
            .text {
                color: red;
            }
        }
        :host{
            ${spacingvar ? 'display: block;' : ''}
            ${spacingvar || ''}
        }
        .text {
            font-size: var(--sketchFontSizeDefault, 1rem);
            display: block;
            font-weight: var(--sketchFontWeight);
            color: var(--sketchColorText);
            font-family: var(--sketchFontFamily);
        }
        .text.bodycopybold{
            font-weight: 700;
        }
        .text.bodycopyemphasized {
            font-size: 1.2rem;
        }
        .text.bodycopyemphasizedbold {
            font-size: 1.2rem;
            font-weight: 700;
        }
        .text.footnote,
        .text.label {
            font-size: var(--sketchFontSizeLabel, 0.8rem);
            letter-spacing: 0.2px;
        }
        .text.primary {
            color: var(--sketchColorPrimary);
        }
        .text.error {
            color: var(--sketchColorError);
        }
        .text.success {
            color: var(--sketchColorSuccess);
        }
        .text.warning {
            color: var(--sketchColorWarning);
        }
        .text.fieldnote {
            font-size: 0.7rem;
            letter-spacing: 0.2px;
        }
        </style>
        <span class="text ${this.variant} ${this.color}">
            <slot></slot>
        </span>`;
    }
}
