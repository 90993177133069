export const translations = {
    N: {
        labelStartDate: `Start datum`,
        labelEndCountButton: `Vast aantal`,
        labelEndDate: `Herhaal tot`,
        labelEndDateButton: `Tot en met`,
        labelFrequencyDays: `Dagen`,
        labelFrequencyMonths: `Maanden`,
        labelFrequencyWeeks: `Weken`,
        labelFrequencyYears: `Jaar`,
        labelInMonths: `In maanden`,
        labelMonthDays: `Op dagen`,
        labelInterval: `Elke`,
        labelMonday: `Maandag`,
        labelTuesday: `Dinsdag`,
        labelWednesday: `Woensdag`,
        labelThursday: `Donderdag`,
        labelFriday: `Vrijdag`,
        labelSaturday: `Zaterdag`,
        labelSunday: `Zondag`,
        labelWeekday: `Weekdag`,
        labelWeekendday: `Weekenddag`,
        labelOnce: `Eenmalig op`,
        labelDay: `Dag`,
        labelJanuary: `Januari`,
        labelFebruary: `Februari`,
        labelMarch: `Maart`,
        labelApril: `April`,
        labelMay: `Mei`,
        labelJune: `Juni`,
        labelJuly: `Juli`,
        labelAugust: `Augustus`,
        labelSeptember: `September`,
        labelOctober: `Oktober`,
        labelNovember: `November`,
        labelDecember: `December`,
        labelPattern: `Patroon`,
        labelOnWeekdays: `Op`,
        labelRepeatCountSuffix: `herhalingen`,
        labelFirst: `Eerste`,
        labelSecond: `Tweede`,
        labelThird: `Derde`,
        labelFourth: `Vierde`,
        labelLast: `Laatste`,
        labelOfMonth: `van`,
        messageStartdateRequired: `Start datum is een verplicht veld`,
        messageEnddateRequired: `Eind datum is een verplicht veld`,
        messageRepeatcountRequired: `Aantal herhalingen moet een waarde groter dan 0 zijn`,
        messageIntervalRequired: `Het interval moet een waarde groter dan 0 zijn`,
    },
    E: {
        labelStartDate: `Start date`,
        labelEndCountButton: `Fixed number of repeats`,
        labelEndDate: `Until`,
        labelEndDateButton: `Until`,
        labelFrequencyDays: `Days`,
        labelFrequencyMonths: `Months`,
        labelFrequencyWeeks: `Weeks`,
        labelFrequencyYears: `Years`,
        labelInMonths: `In months`,
        labelMonthDays: `On days`,
        labelInterval: `Every`,
        labelMonday: `Monday`,
        labelTuesday: `Tuesday`,
        labelWednesday: `Wednesday`,
        labelThursday: `Thursday`,
        labelFriday: `Friday`,
        labelSaturday: `Saturday`,
        labelSunday: `Sunday`,
        labelWeekday: `Weekday`,
        labelWeekendday: `Weekend day`,
        labelOnce: `Once on`,
        labelDay: `Day`,
        labelJanuary: `January`,
        labelFebruary: `February`,
        labelMarch: `March`,
        labelApril: `April`,
        labelMay: `May`,
        labelJune: `Juni`,
        labelJuly: `July`,
        labelAugust: `August`,
        labelSeptember: `September`,
        labelOctober: `October`,
        labelNovember: `November`,
        labelDecember: `December`,
        labelPattern: `Pattern`,
        labelOnWeekdays: `On`,
        labelRepeatCountSuffix: `times`,
        labelFirst: `First`,
        labelSecond: `Second`,
        labelThird: `Third`,
        labelFourth: `Fourth`,
        labelLast: `Last`,
        labelOfMonth: `of`,
        messageStartdateRequired: `Start date is a required field`,
        messageEnddateRequired: `End date is a required field`,
        messageRepeatcountRequired: `Repeat count must be greater than 0`,
        messageIntervalRequired: `The interval must be greater than 0`,
    },
    F: {
        labelStartDate: `Date de début`,
        labelEndCountButton: `Montant fixé`,
        labelEndDate: `Montant fixé`,
        labelEndDateButton: `Jusqu'à`,
        labelFrequencyDays: `Jours`,
        labelFrequencyMonths: `Mois`,
        labelFrequencyWeeks: `Semaines`,
        labelFrequencyYears: `Années`,
        labelInMonths: `Dans les mois`,
        labelMonthDays: `Sur`,
        labelInterval: `Chaque`,
        labelMonday: `Lundi`,
        labelTuesday: `Mardi`,
        labelWednesday: `Mercredi`,
        labelThursday: `Jeudi`,
        labelFriday: `Vendredi`,
        labelSaturday: `Samedi`,
        labelSunday: `Dimanche`,
        labelWeekday: `Jour de la semaine`,
        labelWeekendday: `Jour de weekend`,
        labelOnce: `une fois sur`,
        labelDay: `Jour`,
        labelJanuary: `Janvier`,
        labelFebruary: `Février`,
        labelMarch: `Mars`,
        labelApril: `Avril`,
        labelMay: `Mai`,
        labelJune: `Juin`,
        labelJuly: `Juillet`,
        labelAugust: `Août`,
        labelSeptember: `Septembre`,
        labelOctober: `Octobre`,
        labelNovember: `Novembre`,
        labelDecember: `Décembre`,
        labelPattern: `Modèle`,
        labelOnWeekdays: `Sur`,
        labelRepeatCountSuffix: `fois`,
        labelFirst: `Premier`,
        labelSecond: `Deuxième`,
        labelThird: `Troisième`,
        labelFourth: `Quatrième`,
        labelLast: `Dernier`,
        labelOfMonth: `de`,
        messageStartdateRequired: `La date de début est un champ obligatoire`,
        messageEnddateRequired: `La date de fin est un champ obligatoire`,
        messageRepeatcountRequired: `Le nombre de répétitions doit être supérieur à 0`,
        messageIntervalRequired: `L intervalle doit être supérieur à 0`,
    },
};
