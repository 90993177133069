import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchButton extends HTMLElement {
    static formAssociated = true;

    $internals = null;

    $fallbackmode = false;

    $shadowRoot;

    static get observedAttributes() {
        return [
            'disabled',
            'id',
            'label',
            'name',
            'size',
            'value',
            'variant',
            'submit',
            'extraStyle',
            'icon',
            'iconPosition',
            'noEndRounding',
            'noStartRounding',
        ];
    }

    constructor() {
        super();
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get label() {
        return this.getAttribute('label') || '';
    }

    get id() {
        const id = this.getAttribute('id');
        if (id !== null && id !== undefined) {
            return id;
        }
        const name = this.getAttribute('name');
        if (name !== null && name !== undefined) {
            return `btn-${name}`;
        }

        return 'empty';
    }

    get name() {
        return this.getAttribute('name');
    }

    get size() {
        if (
            this.getAttribute('size') !== null &&
            this.getAttribute('size') !== undefined
        ) {
            return this.getAttribute('size');
        }
        return 'md';
    }

    get value() {
        return this.getAttribute('value');
    }

    get variant() {
        return this.getAttribute('variant') || 'default';
    }

    get submit() {
        return this.getAttribute('submit');
    }

    set submit(value) {
        this.setAttribute('submit', value);
    }

    get extraStyle() {
        return this.getAttribute('extraStyle');
    }

    set extraStyle(value) {
        this.setAttribute('extraStyle', value);
    }

    get icon() {
        return this.getAttribute('icon');
    }

    set icon(value) {
        this.setAttribute('icon', value);
    }

    get iconPosition() {
        return this.getAttribute('iconPosition') || 'before';
    }

    set iconPosition(value) {
        this.setAttribute('iconPosition', value);
    }

    get noEndRounding() {
        return isBooleanAttributeTrue(this.getAttribute('noEndRounding'));
    }

    set noEndRounding(value) {
        this.setAttribute('noEndRounding', value);
    }

    get noStartRounding() {
        return isBooleanAttributeTrue(this.getAttribute('noStartRounding'));
    }

    set noStartRounding(value) {
        this.setAttribute('noStartRounding', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        const button = this.$shadowRoot.querySelector('button');
        if (button) {
            button.addEventListener('click', this._dispatchClick);
        }
    }

    attributeChangedCallback(property, oldValue, newValue) {
        this.render();
    }

    _dispatchClick = () => {
        if (this._isSubmit() && !this.fallbackmode) {
            if (this.$internals.form && this.$internals.form.reportValidity()) {
                this.$internals.form.submit();
            }
        } else {
            const event = new MouseEvent('sk-click', {
                bubbles: true,
                composed: true,
            });
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                composed: true,
            });
            this.dispatchEvent(event);
            this.dispatchEvent(clickEvent);
        }
    };

    _isSubmit = () => this.submit !== undefined && this.submit != null;

    _getIconMargin = () => {
        if (this.iconPosition === 'before') {
            return '0 var(--sketchSpacing2) 0 0';
        }
        if (this.iconPosition === 'after') {
            return '0 0 0 var(--sketchSpacing2)';
        }
    };

    render() {
        const cssClass = `sketch-button--${this.variant} size--${this.size}`;
        this.$shadowRoot.innerHTML = `
        <style>
        :host {
            width: fit-content;
        }
        button {
            display: block;
            border: solid 1px gray;
            padding: var(--sketchPaddingButton);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: var(--sketchBorderRadiusButton);
            ${this.noEndRounding ? 'border-top-right-radius: 0;border-bottom-right-radius: 0;' : ''}
            ${this.noStartRounding ? 'border-top-left-radius: 0;border-bottom-left-radius: 0;' : ''}
        }
        button:hover {
            cursor: pointer;
        }
        button[disabled]:hover {
            cursor: default;
        }
        button.sketch-button--primary {
            background-color: var(--sketchBackgroundColorPrimary);
            color: var(--sketchColorTextOnPrimary);
            border: var(--sketchBorderButtonPrimary, 1px solid var(--sketchBackgroundColorPrimary))
        }
        button.sketch-button--primary:hover {
            background-color: var(--sketchBackgroundColorPrimaryHover);
            color: var(--sketchColorTextOnPrimary);
            border: var(--sketchBorderButtonPrimary, 1px solid var(--sketchBackgroundColorPrimary))
        }
        button[disabled].sketch-button--primary {
            background-color: var(--sketchBackgroundColorButtonPrimaryDisabled);
            color: var(--sketchColorTextOnPrimaryDisabled);
            border: var(--sketchBorderButtonPrimary, 1px solid var(--sketchBackgroundColorPrimary))
        }

        button.sketch-button--default {
            background-color: var(--sketchBackgroundColorButtonSecondary);
            color: var(--sketchColorSecondaryButton);
            border: var(--sketchBorderButtonSecondary, 1px solid var(--sketchBackgroundColorButtonSecondary));
        }
        button.sketch-button--default:hover {
            background-color: var(--sketchBackgroundColorButtonSecondaryHover);
            color: var(--sketchColorSecondaryButtonHover);
            border: var(--sketchBorderButtonSecondary, 1px solid var(--sketchBackgroundColorButtonSecondary));
        }
        button[disabled].sketch-button--default {
            background-color: var(--sketchBackgroundColorButtonSecondaryDisabled);
            color: var(--sketchColorTextOnSecondaryDisabled);
            border: var(--sketchBorderButtonSecondary, 1px solid var(--sketchBackgroundColorButtonSecondary));
        }

        button.sketch-button--tertiary {
            background-color: var(--sketchBackgroundColorTertiary);
            color: var(--sketchColorTextOnTertiary);
            border: 1px solid var(--sketchBackgroundColorTertiary);
        }
        button.sketch-button--tertiary:hover {
            background-color: var(--sketchBackgroundColorTertiaryHover);
            color: var(--sketchColorTextOnTertiary);
            border: 1px solid var(--sketchBackgroundColorTertiary);
        }
        button[disabled].sketch-button--tertiary {
            background-color: var(--sketchBackgroundColorButtonTertiaryDisabled);
            color: var(--sketchColorTextOnTertiaryDisabled);
            border: 1px solid var(--sketchBackgroundColorTertiary);
        }

        .size--sm {
            height: 24px;
            padding: 4px 10px;
            font-size: calc(var(--sketchFontSizeButton, var(--sketchFontSizeDefault)) * 0.7);
        }
        .size--md {
            /*height: 32px;*/
            padding: var(--sketchPaddingButton);
            font-size: var(--sketchFontSizeButton, var(--sketchFontSizeDefault));
        }
        .size--lg {
            height: 48px;
            padding: 30px 60px;
            font-size: calc(var(--sketchFontSizeButton, var(--sketchFontSizeDefault)) * 1.2);
            font-weight: bold;
        }
        </style>
        <button
            class="${cssClass} ${this.extraCssClass}"
            style="${this.extraStyle}"
            ${this.disabled ? 'disabled' : ''}
            type="${this._isSubmit() ? 'submit' : 'button'}"
            ${this.name ? `name="${this.name}"` : ''}
            ${this.value ? `value="${this.value}"` : ''}
            ${this.id ? `id="${this.id}"` : ''}
        >${
            this.icon && this.iconPosition === 'before'
                ? `<sketch-icon icon="${this.icon}" size="${this.size}" style="margin: ${this.label !== '' ? this._getIconMargin() : 0};"></sketch-icon>`
                : ''
        }${this.label}${
            this.icon && this.iconPosition === 'after'
                ? `<sketch-icon icon="${this.icon}" size="${this.size}" style="margin: ${this.label !== '' ? this._getIconMargin() : 0};"></sketch-icon>`
                : ''
        }</button>`;
    }
}
