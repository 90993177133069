export class SketchBackgroundbox extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open'});
    }

    connectedCallback() {
        this.render();
    }

    render() {
        this.shadow.innerHTML = `
        <style>
        .background {
            background-color: var(--sketchBackgroundColorSection);
            border-radius: var(--sketchBorderRadiusSection);
            box-shadow: var(--sketchBoxShadowSection);
            border: var(--sketchBorderSection);
            display: flex;
            flex-direction: column;
            padding: 2rem;
        }
        </style>
        <div class="background"><slot></slot></div>`;
    }
}
