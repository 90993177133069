import styles from '../assets/icomoon/style.css';

export class SketchIcon extends HTMLElement {
    static get observedAttributes() {
        return ['color', 'icon', 'size'];
    }

    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    get icon() {
        return this.getAttribute('icon');
    }

    set icon(value) {
        this.setAttribute('icon', value);
    }

    get size() {
        return this.getAttribute('size') || 'md';
    }

    set size(value) {
        this.setAttribute('size', value);
    }

    get color() {
        return this.getAttribute('color');
    }

    set color(value) {
        this.setAttribute('color', value);
    }

    connectedCallback() {
        this.render();
    }

    attributeChangedCallback(property, oldValue, newValue) {
        this.render();
    }

    render() {
        this.shadow.innerHTML = `
<style>
${styles}    
:host {
    height: fit-content;
    width: fit-content;
}
.icon {
    display: block;
    stroke-width: 0;
    stroke: var(--sketchColorText);
    fill: var(--sketchColorText);
  }
.xs {
    height: .8rem;
    width: .8rem;
    font-size: 13.3px;
}
.sm {
    height: 1rem;
    width: 1rem;
    // font-size: 1rem;
}
.md {
    height: 1.2rem;
    width: 1.2rem;
    // font-size: 1.2rem;
}
.primary {
    color: var(--sketchIconColorPrimary);
}
.secondary {
    color: var(--sketchIconColorSecondary);
}
.success {
    color: var(--sketchColorSuccess);
}
.error{
    color: var(--sketchColorError);
}
.warning{
    color: var(--sketchColorWarning);
}
.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<span class="iconWrapper icon-${this.icon} ${this.size} ${this.color}"></span>`;
    }
}
