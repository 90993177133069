import * as jsondiffpatch from 'jsondiffpatch';
import {generateUniqueId} from '../utils/index.js';

const _renderActionIndicator = (diffLine) => {
    let symbol = '';
    if (diffLine.action === 'modified') {
        symbol = '<sketch-icon icon="pencil" size="xs"></sketch-icon>';
    }
    if (diffLine.action === 'add' || diffLine.action === 'added') {
        symbol = '<sketch-icon icon="plus" size="xs"></sketch-icon>';
    }
    if (diffLine.action === 'remove' || diffLine.action === 'removed') {
        symbol = '<sketch-icon icon="trash-o" size="xs"></sketch-icon>';
    }
    return `<div class="actionindicator ${diffLine.action}">${symbol}</div>`;
};

const _renderDiffValueLine = (diffLine) =>
    `<div class="jsondiffline">
    <div class="property ${diffLine.action}">
        <span class="key">
            ${diffLine.key}
        </span>
        <span class="mobileicon">${_renderActionIndicator(diffLine)}</span>
    </div>
    <div class="diffsummary">
        <div class="oldvalue">
        ${diffLine.oldValue}
        </div>
        <div class="newvalue">
            ${diffLine.newValue}
        </div>
    </div>
</div>`;

const _getDeltaAction = (value) => {
    let action = '';
    if (value.length === 1) {
        action = 'add';
    }
    if (value.length === 2) {
        action = 'modified';
    }
    if (value.length === 3) {
        action = 'removed';
    }
    return action;
};

const _getOldValue = (deltaVal, action) => {
    let value = '';
    if (action === 'modified') {
        value = deltaVal[0];
    }
    if (action === 'removed') {
        value = deltaVal[0];
    }
    return value;
};

const _getNewValue = (deltaVal, action) => {
    let value = '';
    if (action === 'add') {
        value = deltaVal[0];
    }
    if (action === 'modified') {
        value = deltaVal[1];
    }
    return value;
};

export class SketchJsonDiff extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    $shadowRoot;

    _deepestLevel = 1;

    static get observedAttributes() {
        return [
            'oldjsonstring',
            'newjsonstring',
            'state',
            'visible',
            'rootNodetitle',
            'id',
            'rootNodeExpandBehavior',
        ];
    }

    constructor() {
        super();
        /* for form elements */
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            console.log('### attachInternals error', error);
        }
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get oldjsonstring() {
        return this.getAttribute('oldjsonstring');
    }

    set oldjsonstring(value) {
        this.setAttribute('oldjsonstring', value);
    }

    get newjsonstring() {
        return this.getAttribute('newjsonstring');
    }

    set newjsonstring(value) {
        this.setAttribute('newjsonstring', value);
    }

    get state() {
        return this.getAttribute('state') || 'collapsed';
    }

    set state(value) {
        this.setAttribute('state', value);
    }

    get rootNodetitle() {
        return this.getAttribute('rootNodetitle') || 'Details';
    }

    set rootNodetitle(value) {
        this.setAttribute('rootNodetitle', value);
    }

    get id() {
        return this.getAttribute('id') || 'Summary';
    }

    set id(value) {
        this.setAttribute('id', value);
    }

    get rootNodeExpandBehavior() {
        return this.getAttribute('rootNodeExpandBehavior') || 'default';
    }

    set rootNodeExpandBehavior(value) {
        this.setAttribute('rootNodeExpandBehavior', value);
    }

    get visible() {
        if (this.getAttribute('visible') === null) {
            return true;
        }
        if (typeof this.getAttribute('visible') === 'string') {
            return this.getAttribute('visible') === 'true';
        }
        return this.getAttribute('visible');
    }

    set visible(value) {
        this.setAttribute('visible', value);
    }

    connectedCallback() {
        this.render();
        this._bindEventHandlers();
    }

    attributeChangedCallback(property, oldValue, newValue) {
        if (property === 'visible' && oldValue !== newValue) {
            this.render();
            this._bindEventHandlers();
        }
    }

    _bindEventHandlers = () => {
        this.$shadowRoot
            .querySelectorAll('.jsonkeyline.path')
            .forEach((line) => {
                if (
                    this.rootNodeExpandBehavior !== 'explode' ||
                    (this.rootNodeExpandBehavior === 'explode' &&
                        !line.classList.contains('rootnode'))
                ) {
                    // root click should behave as normal
                    line.addEventListener('click', (event) => {
                        let {targetLevel} = event.target.dataset;
                        if (!targetLevel) {
                            targetLevel =
                                event.target.parentNode.dataset.targetLevel;
                        }
                        this._toggleLevel(targetLevel);
                    });
                } else {
                    // should explode on root click
                    this.$shadowRoot
                        .querySelector('.rootnode')
                        .addEventListener('click', () => {
                            this.$shadowRoot
                                .querySelectorAll('[data-target-level]')
                                .forEach((node) =>
                                    this._toggleLevel(node.dataset.targetLevel)
                                );
                        });
                }
            });
    };

    _toggleLevel = (level) => {
        if (level === 1) {
            const levels = this.$shadowRoot.querySelectorAll(`[data-level]`);
            levels.forEach((level) => {
                this._toggleLevel(level.dataset.level);
            });
        } else {
            this.$shadowRoot
                .querySelector(`[data-level="${level}"]`)
                .classList.toggle('collapsed');
            const icon = this.$shadowRoot.querySelector(
                `[data-target-level="${level}"] sketch-icon`
            );
            if (icon) {
                icon.setAttribute(
                    'icon',
                    icon.icon === 'caret-right' ? 'caret-down' : 'caret-right'
                );
            }
        }
    };

    _renderNestedDivs = (diffLines) => {
        let result = '';
        let previousLevel = 0;
        let currentDiv = '';
        let prevUnique = '';

        diffLines.forEach((diffLine, index) => {
            const unique = generateUniqueId();
            if (diffLine.level > previousLevel) {
                currentDiv += `<div class="jsonkeyline ${
                    diffLine.level !== 1 ? this.state : 'expanded'
                } ${index === 0 ? 'root' : ''}" data-level="${prevUnique}">`;
            } else if (diffLine.level < previousLevel) {
                const levelsToClose = previousLevel - diffLine.level;
                for (let i = 0; i < levelsToClose; i += 1) {
                    currentDiv += `</div>`;
                }
            }

            if (diffLine.hasOwnProperty('oldValue')) {
                currentDiv += _renderDiffValueLine(diffLine);
            } else {
                currentDiv += `<div class="jsonkeyline path ${
                    diffLine.level === 1 ? 'rootnode' : ''
                }" data-level="${
                    diffLine.level
                }" data-target-level="${unique}"><sketch-icon icon="${
                    this.state === 'collapsed' ? 'caret-right' : 'caret-down'
                }" size="sm"></sketch-icon>
                <span class="key">${diffLine.key}</span></div>`;
            }
            previousLevel = diffLine.level;
            prevUnique = unique;
            result += currentDiv;
            currentDiv = '';
        });

        // Close any remaining open divs
        for (let i = 0; i <= previousLevel; i += 1) {
            result += `</div>`;
        }

        return result;
    };

    /** transform the delta from jsondiffpatch to a flat array */
    _buildDiffLines = (delta, entries = [], level = 2, mode = '') => {
        if (delta) {
            Object.keys(delta).forEach((key) => {
                if (
                    typeof delta[key] === 'object' &&
                    !Array.isArray(delta[key])
                ) {
                    entries.push({key, level});
                    if (level > this._deepestLevel) {
                        this._deepestLevel = level;
                    }
                    return this._buildDiffLines(delta[key], entries, level + 1);
                }
                const action = _getDeltaAction(delta[key]);
                const newValue = _getNewValue(delta[key], action);
                const oldValue = _getOldValue(delta[key], action);
                if (
                    typeof newValue !== 'object' &&
                    typeof oldValue !== 'object'
                ) {
                    entries.push({
                        key,
                        level,
                        oldValue,
                        newValue,
                        action: mode || action,
                    });
                    if (level > this._deepestLevel) {
                        this._deepestLevel = level;
                    }
                } else {
                    entries.push({key, level});
                    if (level > this._deepestLevel) {
                        this._deepestLevel = level;
                    }
                    let property = 'newValue';
                    let value = newValue;
                    if (typeof oldValue === 'object') {
                        property = 'oldValue';
                        value = oldValue;
                    }
                    const explosion = this._explodeJsonObjectRecursive(
                        value,
                        level + 1,
                        property
                    );
                    explosion.forEach((entry) => {
                        const newEntry = {...entry};
                        newEntry.action = mode || action;
                        entries.push(newEntry);
                    });
                }
            });
        }

        return entries;
    };

    /**
     * A recursive function to explode a JSON object into an array of key-value pairs with specified properties.
     *
     * @param {Object} jsonObj - The JSON object to be exploded.
     * @param {number} [level=0] - The current level of recursion.
     * @param {string} [targetProperty='newValue'] - The target property name for the exploded key-value pairs.
     * @return {Array} The array of key-value pairs with specified properties.
     */
    _explodeJsonObjectRecursive(
        jsonObj,
        level = 0,
        targetProperty = 'newValue'
    ) {
        let result = [];
        Object.keys(jsonObj).forEach((key) => {
            if (typeof jsonObj[key] === 'object' && jsonObj[key] !== null) {
                result.push({key, level});
                result = result.concat(
                    this._explodeJsonObjectRecursive(
                        jsonObj[key],
                        level + 1,
                        targetProperty
                    )
                );
            } else {
                result.push({
                    key,
                    [targetProperty]: jsonObj[key],
                    [targetProperty === 'oldValue' ? 'newValue' : 'oldValue']:
                        '',
                    level,
                });
            }
        });
        return result;
    }

    render() {
        if (this.visible) {
            const delta = jsondiffpatch.diff(
                JSON.parse(this.oldjsonstring),
                JSON.parse(this.newjsonstring)
            );
            const diffLines = this._buildDiffLines(delta);
            diffLines.unshift({key: this.rootNodetitle, level: 1});
            this.$shadowRoot.innerHTML = `
<style>
.jsondiffwrapper {
    container-type: inline-size;
}
.key {
    font-size: var(--sketchFontSizeLabel);
    letter-spacing: 0.2px;
}
.jsonkeyline.root{
    padding-left: 0;
}
.jsonkeyline,
.jsondiffline {
    padding-left: var(--sketchSpacing6);
}
.jsonkeyline.path {
    display: flex;
    align-items: center;
    padding-top: var(--sketchSpacing2);
    padding-bottom: var(--sketchSpacing2);
}
.oldvalue {
    color: var(--sketchColorNeutralExtraDark);
    text-decoration: line-through;
}
.collapsed {
    display: none;
}
.property {
    display: flex;
    align-items: baseline;
    gap: var(--sketchSpacing2);
    width: fit-content;
    padding: var(--sketchSpacing1) var(--sketchSpacing2);
}
.property.modified {
    background-color: #b9d1e2;
}
.property.add {
    background-color: #c4e0c4;
}
.property.removed {
    background-color: #efcdcd;
}
.mobileicon {
    display: inline;
}
.diffsummary {
    padding-left: var(--sketchSpacing3);
    display: flex;
    flex-direction: column;
    gap: var(--sketchSpacing2);
}
.jsondiffline {
    padding-top: var(--sketchSpacing2);
}
.jsonkeyline.path:hover {
    cursor: pointer;
}
.collapsed {
    display: none;
}
.jsondiffwrapper {
    container-type: inline-size;
}
.jsonkeyline.path:hover {
    color: var(--sketchColorLinkHover);
}
.jsondiffline {
    display: flex;
    flex-direction: column;    
    padding-bottom: var(--sketchSpacing2);
}
@container (min-width: 800px) {
    .diffsummary {
        flex-direction: row;
        gap: var(--sketchSpacing8);
    }
}
@media (min-width: 576px) {
    .jsonkeyline.path {
        padding-top: 0;
        padding-bottom: 0;
    }
}
    </style>
    <div class="jsondiffwrapper">
    ${this._renderNestedDivs(diffLines)}
    </div>`;
        } else {
            this.$shadowRoot.innerHTML = `
            <style>
            .lds-dual-ring {
                color: #1c4c5b;
            }
            .lds-dual-ring,
            .lds-dual-ring:after {
                box-sizing: border-box;
            }
            .lds-dual-ring {
                display: inline-block;
                width: 20px;
                height: 20px;
            }
            .lds-dual-ring:after {
                content: " ";
                display: block;
                width: 16px;
                height: 16px;
                margin: 4px;
                border-radius: 50%;
                border: 1.5px solid currentColor;
                border-color: currentColor transparent currentColor transparent;
                animation: lds-dual-ring 1.2s linear infinite;
            }
            @keyframes lds-dual-ring {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            </style>
            <div class="lds-dual-ring"></div>`;
        }
    }
}
