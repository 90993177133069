import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchGrid extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open'});
    }

    static get observedAttributes() {
        return ['columns', 'columnDistribution', 'withgap', 'contentalignment'];
    }

    set withgap(value) {
        this.setAttribute('withgap', value);
    }

    get withgap() {
        return isBooleanAttributeTrue(this.getAttribute('withgap'));
    }

    set columns(value) {
        this.setAttribute('columns', value);
    }

    get columns() {
        return this.getAttribute('columns');
    }

    set columnDistribution(value) {
        this.setAttribute('columnDistribution', value);
    }

    get columnDistribution() {
        return this.getAttribute('columnDistribution');
    }

    set contentalignment(value) {
        this.setAttribute('contentalignment', value);
    }

    get contentalignment() {
        return this.getAttribute('contentalignment');
    }

    connectedCallback() {
        this.render();
    }

    _generateClasses() {
        let columnsTemplate = `[`;
        const colParts = this.columnDistribution.split(';');
        columnsTemplate = colParts.reduce(
            (accumulator, currentValue, index) => {
                return `${accumulator} col-${
                    index + 1
                }-start] ${currentValue}% [col-${index + 1}-end`;
            },
            columnsTemplate
        );
        columnsTemplate = `${columnsTemplate}]`;
        let styling = `.grid.custom{grid-template-columns: 1fr;}`;
        styling = `${styling} @media (min-width: 576px) {.grid.custom{`;
        styling = `${styling}grid-template-columns: ${columnsTemplate};}}`;
        return styling;
    }

    render() {
        this.shadow.innerHTML = `
        <style>
        .grid {
            display: grid;
            ${this.withgap ? 'grid-gap: var(--sketchSpacing6, 1rem);' : ''}
        }
        .cols2,
        .cols3,
        .cols4 {
            grid-template-columns: 1fr;
        }
        .contentalign-center {
            align-items: center;
        }
        .contentalign-start {
            align-items: start;
        }
        .contentalign-end {
            align-items: end;
        }
        .contentalign-baseline {
            align-items: baseline;
        }
        @media (min-width: 576px) {
            .cols2,
            .cols3,
            .cols4 {
                grid-template-columns: 1fr 1fr;
            }
        }
        @media (min-width: 768px) {
            .cols2 {
                grid-template-columns: 1fr 1fr;
            }
            .cols3,
            .cols4 {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
        @media (min-width: 992px) {
            .cols4 {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
        ${this.columnDistribution ? this._generateClasses() : ''}
        </style>
        <div class="grid ${
            this.columnDistribution ? 'custom' : 'cols' + this.columns
        } ${
            this.contentalignment !== undefined
                ? `contentalign-${this.contentalignment}`
                : ''
        }">
            <slot></slot>
        </div>`;
    }
}
