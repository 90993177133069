export class SketchMessage extends HTMLElement {
    static formAssociated = true;

    $shadowRoot;

    static get observedAttributes() {
        return ['variant', 'colspan'];
    }

    constructor() {
        super();
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get colspan() {
        return this.getAttribute('colspan');
    }

    set colspan(value) {
        this.setAttribute('colspan', value);
    }

    connectedCallback() {
        this.render();
        const slotElement =
            this.$shadowRoot.querySelector('slot[name="extra"]');
        slotElement.addEventListener('slotchange', () => {
            this.$shadowRoot.querySelector('.extra')?.classList.add('visible');
        });
    }

    render() {
        this.$shadowRoot.innerHTML = `
        <style>
        :host {
            ${this.colspan ? `grid-column: span ${this.colspan}` : ''}
        }
        .msg {
            padding: var(--sketchSpacing6) var(--sketchSpacing7);
            box-shadow: 2px 4px 5px 0px var(--sketchColorNeutralLight);
            margin-bottom: var(--sketchSpacing8);
            color: var(--sketchColorText);
        }
        .msg-error {
            background-color: var(--sketchBackgroundColorError);
            color: var(--sketchColorError);
        }
        .msg-success {
            background-color: var(--sketchBackgroundColorSuccess);
            color: var(--sketchColorSuccess);
        }
        .msg-info {
            background-color: var(--sketchBackgroundColorInfo);
            color: var(--sketchColorInfo);
        }
        .msg-warning {
            background-color: var(--sketchBackgroundColorWarning);
            color: var(--sketchColorWarning);
        }
        .extra {
            margin-top: var(--sketchSpacing6);
            color: var(--sketchColorText);
            font-style: italic;
            display: none;
        }
        .extra.visible{
            display: block;}
        </style>
        <div class="msg msg-${this.variant}">
            <slot></slot>
            <div class="extra">
                <slot name="extra"></slot>
            </div>
        </div>`;
    }
}
