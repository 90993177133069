// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/icomoon.eot?ek32tq", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/icomoon.ttf?ek32tq", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/icomoon.woff?ek32tq", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/icomoon.svg?ek32tq", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'icomoon';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src:
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
        url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype'),
        url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
        url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
`, "",{"version":3,"sources":["webpack://./src/assets/icomoon/font.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4CAAoC;IACpC;;;;6DAIyD;IACzD,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":["@font-face {\n    font-family: 'icomoon';\n    src: url('fonts/icomoon.eot?ek32tq');\n    src:\n        url('fonts/icomoon.eot?ek32tq#iefix') format('embedded-opentype'),\n        url('fonts/icomoon.ttf?ek32tq') format('truetype'),\n        url('fonts/icomoon.woff?ek32tq') format('woff'),\n        url('fonts/icomoon.svg?ek32tq#icomoon') format('svg');\n    font-weight: normal;\n    font-style: normal;\n    font-display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
