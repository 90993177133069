import leaflet from 'leaflet';
import styles from './SketchMap.style.css';
import markerIcon from '../assets/map/images/marker-icon.png';
import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchMap extends HTMLElement {
    $shadowRoot;

    $map;

    static get observedAttributes() {
        return [
            'boundingBox',
            'height',
            'hidden',
            'markers',
            'width',
            'zoomLevel',
        ];
    }

    constructor() {
        super();
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get boundingBox() {
        if (this.getAttribute('boundingBox')) {
            return JSON.parse(this.getAttribute('boundingBox'));
        }
        return this.getAttribute('boundingBox');
    }

    set boundingBox(value) {
        this.setAttribute('boundingBox', value);
    }

    get hidden() {
        return isBooleanAttributeTrue(this.getAttribute('hidden'));
    }

    set hidden(value) {
        this.setAttribute('hidden', value);
    }

    get height() {
        return this.getAttribute('height') || 200;
    }

    set height(value) {
        this.setAttribute('height', value);
    }

    get markers() {
        if (this.getAttribute('markers')) {
            return JSON.parse(this.getAttribute('markers'));
        }
        return [];
    }

    set markers(value) {
        this.setAttribute('markers', JSON.parse(value));
    }

    get width() {
        return this.getAttribute('width') || '100%';
    }

    set width(value) {
        this.setAttribute('width', value);
    }

    get zoomLevel() {
        return Number(this.getAttribute('zoomLevel'));
    }

    set zoomLevel(value) {
        this.setAttribute('zoomLevel', value);
    }

    _setMapBounds = (featureGroup = null) => {
        if (this.boundingBox) {
            const corner1 = leaflet.latLng(
                this.boundingBox.lat1,
                this.boundingBox.lng1
            );
            const corner2 = leaflet.latLng(
                this.boundingBox.lat2,
                this.boundingBox.lng2
            );
            const bounds = leaflet.latLngBounds(corner1, corner2);
            this.$map.fitBounds(bounds);
        } else {
            if (featureGroup) {
                let featureGroupBounds = featureGroup.getBounds();
                featureGroupBounds = featureGroupBounds.pad(0.2);
                this.$map.fitBounds(featureGroupBounds);
            }
        }
    };

    _connectMap = () => {
        if (!this.hidden) {
            const mapDiv = this.$shadowRoot.querySelector('#map');

            this.$map = leaflet.map(mapDiv);

            leaflet
                .tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 19,
                    attribution:
                        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                })
                .addTo(this.$map);
            const pinIcon = leaflet.icon({
                iconUrl: markerIcon,
            });

            const featureGroup = new leaflet.FeatureGroup();
            this.markers.forEach((marker) => {
                const pin = leaflet.marker([marker.lat, marker.lng], {
                    icon: pinIcon,
                });
                featureGroup.addLayer(pin);
                featureGroup.addTo(this.$map);
            });
            this._setMapBounds(featureGroup);
            if (this.zoomLevel) {
                this.$map.setZoom(this.zoomLevel);
            }
        }
    };

    connectedCallback() {
        this.render();
        this._connectMap();
    }

    attributeChangedCallback(property, oldValue, newValue) {
        this.render();
        this._connectMap();
    }

    render() {
        this.$shadowRoot.innerHTML = `
        <style>
        #map {
            height: ${this.height}px;
            width: ${this.width};
            z-index: 0;
            margin-bottom: var(--sketchSpacing8);
        }
        .leaflet-control-attribution {
            display: none;
        }
        ${styles}
        </style>
        ${this.hidden ? '' : '<div id="map"></div>'}`;
    }
}
