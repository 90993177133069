import {isBooleanAttributeTrue} from '../utils/index.js';
import styles from './input.style.css';

export class SketchInput extends HTMLElement {
    static formAssociated = true;

    $input;

    $internals;

    $fallbackmode = false;

    $shadowRoot;

    _touched = false;

    static get observedAttributes() {
        return [
            'disabled',
            'hasError',
            'fieldId',
            'label',
            'name',
            'placeholder',
            'readonly',
            'required',
            'type',
            'value',
            'nomargin',
            'maxwidth',
            'max',
            'subgrid',
            'size',
        ];
    }

    constructor() {
        super();
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
    }

    get maxwidth() {
        return this.getAttribute('maxwidth');
    }

    set maxwidth(value) {
        this.setAttribute('maxwidth', value);
    }

    get nomargin() {
        return this.getAttribute('nomargin');
    }

    set nomargin(value) {
        this.setAttribute('nomargin', value);
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get hasError() {
        return isBooleanAttributeTrue(this.getAttribute('hasError'));
    }

    set hasError(value) {
        this.setAttribute('hasError', value);
    }

    get fieldId() {
        return this.getAttribute('fieldId');
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get placeholder() {
        return this.getAttribute('placeholder');
    }

    set placeholder(value) {
        this.setAttribute('placeholder', value);
    }

    get required() {
        return isBooleanAttributeTrue(this.getAttribute('required'));
    }

    set required(value) {
        this.setAttribute('required', value);
    }

    get type() {
        return this.getAttribute('type');
    }

    set type(value) {
        this.setAttribute('type', value);
    }

    get value() {
        return this.getAttribute('value');
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    get readonly() {
        return isBooleanAttributeTrue(this.getAttribute('readonly'));
    }

    set readonly(value) {
        this.setAttribute('readonly', value);
    }

    get max() {
        return this.getAttribute('max');
    }

    set max(value) {
        this.setAttribute('max', value);
    }

    get subgrid() {
        return isBooleanAttributeTrue(this.getAttribute('subgrid'));
    }

    set subgrid(value) {
        this.setAttribute('subgrid', value);
    }

    get size() {
        return this.getAttribute('size');
    }

    set size(value) {
        this.setAttribute('size', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        this.$input = this.$shadowRoot.querySelector('input');
        if (this.type === 'color') {
            this.$input.addEventListener('input', this._handleChange);
        } else {
            this.$input.addEventListener('keyup', this._handleInput);
            this.$input.addEventListener('focusout', this._handleFocusOut);
            this.$input.addEventListener('change', this._handleChange);
        }
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
            this.$internals.setValidity(
                this.$input.validity,
                this.$input.validationMessage,
                this.$input
            );
        }
    }

    firstUpdated() {
        if (!this.fallbackmode) {
            this.$internals.setValidity(
                this.$input.validity,
                this.$input.validationMessage,
                this.$input
            );
        }
    }

    _handleInput = () => {
        this.value = this.$input.value;

        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.$input.value);
            this.$internals.setValidity(
                this.$input.validity,
                this.$input.validationMessage,
                this.$input
            );
        }
        this._touched = true;
    };

    _handleFocusOut = () => {
        if (this._touched) {
            this.render();
            this.$input = this.$shadowRoot.querySelector('input');
            if (!this.fallbackmode) {
                this.$internals.setValidity(
                    this.$input.validity,
                    this.$input.validationMessage,
                    this.$input
                );
            }
            if (this.type === 'color') {
                this.$input.addEventListener('input', this._handleChange);
            } else {
                this.$input.addEventListener('keyup', this._handleInput);
                this.$input.addEventListener('focusout', this._handleFocusOut);
                this.$input.addEventListener('change', this._handleChange);
            }
        }
    };

    _handleChange = (event) => {
        if (this.type === 'color') {
            this._handleInput();
            this.$shadowRoot.querySelector('#selectedColor').innerHTML =
                event.target.value;
        }
        // new event.constructor(event.type, event);
        this.dispatchEvent(
            new CustomEvent('change', {
                bubbles: true,
                detail: {
                    value: event.target.value,
                },
            })
        );
    };

    render() {
        const cssClasses = this.hasError ? 'error' : '';
        this.$shadowRoot.innerHTML = `
        <style>
        ${styles}
        :host {
            display: flex;
            flex-direction: column;
            
            ${
                this.nomargin === 'true'
                    ? ''
                    : 'margin-bottom: var(--sketchSpacing6);'
            }
        }
        ${
            this.subgrid
                ? ''
                : `
                label {
                    margin-bottom: var(--sketchSpacing1);
                }`
        }
        .inputwrapper {
            display: flex;
            ${this.size ? '' : 'width: 100%;'}
            align-items: center;
        }
        slot[name="suffix"]::slotted(*) {
            margin: 0 0 0 var(--sketchSpacing3);
        }
        input {
            ${this.maxwidth ? `max-width:${this.maxwidth}` : 'max-width: 100%;'}
            
        }
        @media only screen and (min-width: 576px) {
            :host {
                ${
                    this.nomargin === 'true'
                        ? ''
                        : 'margin-bottom: var(--sketchSpacing6);'
                }
                ${
                    this.subgrid
                        ? `
                    display: grid;
                    grid-template-columns: subgrid;
                    grid-column: span 2;`
                        : ''
                }
                
            }
            label {
                display: flex;
                align-items: center;
            }
            
        }
        </style>
        <label for="${this.fieldId}"
            ><sketch-text variant="footnote" color=${
                this.hasError ? 'error' : ''
            }
                >${this.label}</sketch-text
            ></label
        >
        <div class="inputwrapper">
            <input
                class="${cssClasses}"
                id="${this.fieldId}"
                name="${this.name}"
                type="${this.type || 'text'}"
                value="${this.value ? this.value : ''}"
                ${this.disabled ? 'disabled' : ''}
                ${this.max ? `max="${this.max}"` : ''}
                ${this.required ? 'required' : ''}
                ${this.readonly ? 'readonly="readonly"' : ''}
                placeholder="${this.placeholder || ''}"
                ${this.size ? `size="${this.size}"` : ''}
            />
            <slot name="suffix">${this.type === 'color' ? `<sketch-text id="selectedColor" variant="label">${this.value}</sketch-text>` : ''}</slot>
        </div>`;
    }
}
