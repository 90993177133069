// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input,
select {
    margin: 0;
    border: var(--sketchBorderInput);
    line-height: 1;
    font-family: var(--sketchFontFamilyInput);
    font-weight: var(--sketchFontWeight);
    box-sizing: border-box;
    border-radius: var(--sketchBorderRadiusInput);
    height: var(--sketchInputHeight, 30px);
}

input:not([type='color']) {
    display: block;
    padding: var(--sketchPaddingInput);
    vertical-align: middle;
    white-space: normal;
    width: inherit;
}
input[disabled],
input[readonly] {
    background-color: hsl(0, 0%, 96.5%);
    color: rgb(137 130 130);
}
input.error {
    border: 2px solid var(--sketchColorError);
}
input[readonly]:hover {
    cursor: default;
}
input[readonly]:focus {
    outline: none;
}

select {
    width: fit-content;
}
select[disabled],
select[readonly] {
    background-color: hsl(0, 0%, 96.5%);
}
select.error {
    border: 2px solid var(--sketchColorError);
}
`, "",{"version":3,"sources":["webpack://./src/components/input.style.css"],"names":[],"mappings":"AAAA;;IAEI,SAAS;IACT,gCAAgC;IAChC,cAAc;IACd,yCAAyC;IACzC,oCAAoC;IACpC,sBAAsB;IACtB,6CAA6C;IAC7C,sCAAsC;AAC1C;;AAEA;IACI,cAAc;IACd,kCAAkC;IAClC,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;AACA;;IAEI,mCAAmC;IACnC,uBAAuB;AAC3B;AACA;IACI,yCAAyC;AAC7C;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;AACA;;IAEI,mCAAmC;AACvC;AACA;IACI,yCAAyC;AAC7C","sourcesContent":["input,\nselect {\n    margin: 0;\n    border: var(--sketchBorderInput);\n    line-height: 1;\n    font-family: var(--sketchFontFamilyInput);\n    font-weight: var(--sketchFontWeight);\n    box-sizing: border-box;\n    border-radius: var(--sketchBorderRadiusInput);\n    height: var(--sketchInputHeight, 30px);\n}\n\ninput:not([type='color']) {\n    display: block;\n    padding: var(--sketchPaddingInput);\n    vertical-align: middle;\n    white-space: normal;\n    width: inherit;\n}\ninput[disabled],\ninput[readonly] {\n    background-color: hsl(0, 0%, 96.5%);\n    color: rgb(137 130 130);\n}\ninput.error {\n    border: 2px solid var(--sketchColorError);\n}\ninput[readonly]:hover {\n    cursor: default;\n}\ninput[readonly]:focus {\n    outline: none;\n}\n\nselect {\n    width: fit-content;\n}\nselect[disabled],\nselect[readonly] {\n    background-color: hsl(0, 0%, 96.5%);\n}\nselect.error {\n    border: 2px solid var(--sketchColorError);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
